import { Injectable } from '@angular/core';
import { api } from '../../../environments/api_url';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import 'moment-timezone'
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private updateVendorProfle = new Subject<any>();
  eventUpdateVendorProfle$ = this.updateVendorProfle.asObservable();
  private dataSubject = new BehaviorSubject<any>(null); // Default value is null
  public data$ = this.dataSubject.asObservable(); // Observable to listen to changes

  private audio: HTMLAudioElement | null = null;
  isAudioInitialized: boolean = false;
  private interval: any;
  private timeout: any;

  constructor(
    private http: HttpClient,
    private _snackbar: MatSnackBar,

    ) {
      this.audio = new Audio('assets/notification.mp3'); // MP3 file path
      this.audio.loop = false; // Ek baar me sirf ek bar chale
     }

  sendEventProfileUpdate(event: any) {
    this.updateVendorProfle.next(event);
  }
  getStatusList() {
    const url = api.URL + '/api/vendor/status-list';
    return this.http.get(url);
  }
  vendorLocationList(payloadkeyword : string) {
    const url = api.URL + '/api/global/get-vendor-city';
    let payload ={
      keyword: payloadkeyword
    }
    return this.http.post(url, payload);
  }

  getCountries() {
    const url = api.URL + '/api/global/get-countries';
    return this.http.get(url);
  }

  getStates(id: any) {
    let payload = {
      country_id: id
    }
    const url = api.URL + `/api/global/get-states`;
    let dataPayload = this.http.post(
      url,
      payload,
      { observe: 'response' }
    )
    return dataPayload;
  }
  getCities(payload: any) {
    const url = api.URL + `/api/global/get-cities?state_id=${payload}`;
    let dataPayload = this.http.post(
      url,
      { observe: 'response' }
    )
    return dataPayload;
  }

  merchantsType(){
    let payload = {}
    const url = api.URL + `/api/erp/list`;
    return this.http.post(url,payload);
  }

  getTimeLeft(data: string) {
    const specificDate = new Date(data);
    const currentDate = new Date();
    
    const timeDiff = specificDate.getTime() - currentDate.getTime();
    const timeLeftInSeconds = Math.ceil(timeDiff / 1000) + 2 * 60;
    return timeLeftInSeconds + 180
  }



  dataSorting(data: any[], sortingVar: any, type: string, key: string): any[] {
    let sortedData = [...data];
    let objectKey = key;

    if (type === 'number') {
      sortingVar[objectKey] ?
        sortedData.sort((a, b) => a[objectKey] - b[objectKey]) :
        sortedData.sort((a, b) => b[objectKey] - a[objectKey]);
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    if (type === 'date') {
      sortedData.sort((a, b) => {
        const dateA: any = new Date(a[objectKey]);
        const dateB: any = new Date(b[objectKey]);
        return sortingVar[objectKey] ? dateA - dateB : dateB - dateA;
      });
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    if (type === 'string') {
      sortingVar[objectKey] ?
        sortedData.sort((a, b) => a[objectKey].localeCompare(b[objectKey])) :
        sortedData.sort((a, b) => b[objectKey].localeCompare(a[objectKey]));
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    return sortedData;
  }

  formatDate(inputDate: string): string {
    console.log(inputDate);

    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const parts = inputDate.split('-');
    const day = parts[0].padStart(2, '0');
    const monthIndex = months.indexOf(parts[1]) + 1; // Adding 1 because months are 1-indexed
    const month = monthIndex.toString().padStart(2, '0');
    const year = parts[2];

    const formattedDate = `${day}-${months[monthIndex - 1]}-${year}`;
    console.log(formattedDate);

    // If you want to return in the format 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  }


  errorHandler(error: unknown){
    this._snackbar.open("Oops! Something Went Wrong.", '', {
      duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
      panelClass: ['snackbar_red']
    })
    console.log("Somthing Went Wrong on server", error);
  }


  formatEmail(email:string){
    return email.toLowerCase()
  }

  formatPhone(phone:string){
    return phone?.split(' ')?.join('')
  }

  getUKTime(time:any) {
    return time ? moment.utc(time).tz('Europe/London').format('DD-MMM-YYYY h:mm A') : '-';
  }

  // =================State Management==================
  updateData(newData: any) {
    this.dataSubject.next(newData); // Emit the new data
  }

  //==================Notification sound=============

  // Initialize audio (called on user interaction)
  initializeAudio() {
    if (this.audio) {
      this.audio
        .play()
        .then(() => {
          this.audio?.pause(); // Pause immediately to allow future plays
          if (this.audio) {
            this.audio.currentTime = 0;
          }
          this.isAudioInitialized = true;
          console.log('Audio initialized successfully.');
        })
        .catch((error) => {
          console.error('Error initializing audio:', error);
        });
    }
  }

  // Start notification sound (called by WebSocket or other events)
  startNotificationSound() {
    if (!this.audio || !this.isAudioInitialized) {
      console.error('Audio not initialized. Please allow user interaction first.');
      return;
    }
    
    // Stop any previous sound
    this.stopNotificationSound();
    
    // Play audio
    this.audio
      .play()
      .then(() => {
        console.log('Audio started successfully.');
        let snackBarRef = this._snackbar.open('Turn Off Notification Sound', 'Ok', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 2 * 60 * 1000,
          panelClass: ['snackbar_green']
        });
    
        snackBarRef.onAction().subscribe(() => {
          this.stopNotificationSound();
        });
      })
      .catch((error) => {
        console.error('Error playing audio:', error);
      });

    // Repeat every 5 seconds
    this.interval = setInterval(() => {
      if (this.audio) {
        this.audio
          .play()
          .catch((error) => {
            console.error('Error repeating audio:', error);
          });
      }
    }, 5000);

    // Stop automatically after 2 minutes
    this.timeout = setTimeout(() => {
      this.stopNotificationSound();
      console.log('Notification sound stopped automatically after 2 minutes.');
    }, 2 * 60 * 1000); // 2 minutes in milliseconds
  }

  stopNotificationSound() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.audio) {
      this.audio.pause(); // Pause audio
      this.audio.currentTime = 0; // Reset audio to start
    }
  }
}
