import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, catchError, retry, takeUntil, throwError, timer, Subject, of, delay } from 'rxjs';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private _Router: Router,
   
    ) {}
    private   stopRetrySubject = new Subject<void>();
    cancelRetry() {
      this.stopRetrySubject.next();
      this.stopRetrySubject.complete();
    }
    
    getRetryDelay(error: any, retryCount: number) {
      console.log(error, "122", retryCount);
      if(error.status === 401){
        return of(5000).pipe(delay(600000))
      }
      
      if (retryCount > 0 && error.status == 405 || error.status== 422 || error.status== 404) {
        console.log(error.status, "retry");
        if(error.status !== 404){
          this._snackBar.open(error.error.message, '', {
            duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
        this.cancelRetry()
        return  of(0).pipe();
      } 
      else if (retryCount > 2  ) {
        console.log(error.status, "retry")
        return of(5000).pipe(delay(5000));
        
      }
      else {
        return of(0);
      }
    }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    const token = this.authService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
    }
    let maxCount = 1;
    return next.handle(request).pipe(
      
      // takeUntil(this.stopRetrySubject),
      catchError((error) => {   
        
          if(error.status === 404){
            console.log(error,111);
            
            this._snackBar.open(error.error.message, '', {
              duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_red']
            })
          } else{
            this._snackBar.open("Oops! Something Went Wrong.", '', {
              duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_red']
            })
          } 
          
      
         
          if(error.status === 401){
            // this.cancelRetry();
            this._snackBar.open("Token may have expired please log-in again. Unauthorized", 'okay', {   
              duration: 10000,           
              panelClass: ['snackbar_red'],
              horizontalPosition:'end',
              verticalPosition:'top'
            })
            localStorage.clear();
            this._Router.navigate(['login'])
            this.authService.userLoginStatus.set(false)
          }
          if(error.status == 405){
            // this.cancelRetry();
            this._snackBar.open("Token may have expired please log-in again. Unauthorized", 'okay', {   
              duration: 10000,           
              panelClass: ['snackbar_red'],
              horizontalPosition:'end',
              verticalPosition:'top'
            })
            localStorage.clear();
            this._Router.navigate(['login'])
            this.authService.userLoginStatus.set(false)
          }
          console.error('Error occurred11:', error.status);
          
          return throwError(() => error);
      }),
      // retry({
      //   delay: this.getRetryDelay,
      //  // count: 10,
      // }),
  );
  }
}