import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private eventSubjectOrderStatus = new Subject<any>();
  eventOrderStatus$ = this.eventSubjectOrderStatus.asObservable();

  private eventSubjectNewOrderStatus = new Subject<any>();
  eventNewOrderStatus$ = this.eventSubjectNewOrderStatus.asObservable();

  private eventSubjectOrderUpdateStatus = new Subject<any>();
  eventOrderUpdateStatus$ = this.eventSubjectOrderUpdateStatus.asObservable();

  constructor(
    private http:HttpClient
  ) { }

  getOrders(payload:any, isVendor?:boolean) {
    if(payload.status == ''){
      delete payload.status
    }
    if(payload.vendor_ids == ''){
      delete payload.vendor_ids
    }

    

    const adminAPIurl = api.URL + '/api/order/list';
    const vednorURL = api.URL + '/api/order/by-vendor';
    const url = isVendor ?  vednorURL:  adminAPIurl;
     
    if(isVendor){
      payload.vendor_id = localStorage.getItem('hashid')
    }
    return this.http.post(url,payload)
  }
  // New order listing API
  ordersList(payload:any){
    const url = api.URL + '/api/orders/list';
    return this.http.post(url,payload)
  }

  getOrderStatus(){
    return this.http.get(api.URL + '/api/orders/filter/titles');
  }

  sendEventOfOrderStatus(event: any) {
    this.eventSubjectOrderStatus.next(event);
  }
  sendEventOfNewOrderStatus(event: any) {
    this.eventSubjectNewOrderStatus.next(event);
  }
  sendOrderUpdateStatus(event: any) {
    this.eventSubjectOrderUpdateStatus.next(event);
  }


  getOrderDetail(payload: { id?: number | null; vendor_id: any; }) {
    if(payload.vendor_id === 0){
       delete payload.vendor_id
    }
    const adminAPIurl = api.URL + '/api/order/detail';    
    return this.http.post(adminAPIurl,payload)
  }

  getOrderRequest(id:number) {
    const url = api.URL + '/api/broadcast/request-list/byvendor';
    let payload = {
      order_id:id
    }
    return this.http.post(url,payload)
  }
  postOrderRequest(payload:any) {
    const url = api.URL + '/api/broadcast/request-update';    
    return this.http.post(url,payload)
  }
  getOrderRequestList(payload?:any) {
    const url = api.URL + '/api/broadcast/broadcast-list/byvendor';    
    return this.http.post(url,payload)
  }

  updateMerchantPreItem(payload:any){
    const url = api.URL + '/api/order/assignOrder';    
    return this.http.post(url,payload)
  }

  merchantAvailabilityCheck(payload:any){
    const url = api.URL + '/api/order/item/availabilityCheck';    
    return this.http.post(url,payload)
  }

  creatNewOrderStatus(data: any) {
    let acceptedItems = 0
    let rejectedItems = 0
    let pendingRequest = 0
    data.forEach((element: { is_accepted: number; }) => {
      if (element.is_accepted == 2) {
        rejectedItems++
      } else if (element.is_accepted == 1) {
        acceptedItems++
      } else if (element.is_accepted == 0) {
        pendingRequest++
      }
    });
    // console.log(acceptedItems, acceptedItems);
    if (acceptedItems >= data.length) {
      return 1
    } else if (acceptedItems > 0 && acceptedItems < data.length) {
      return 3
    } else if (rejectedItems) {
      return 2
    } else if (pendingRequest) {
      return 0
    } else {
      return null
    }
  }

  checkPriceofLineItems(data: any, newOrder?: boolean) {
    let amount = 0
    newOrder ?
      data.forEach((element: { vendor_product: { price: number; }; quantity: number; }) => amount += element.vendor_product.price * element.quantity)
      :
      data.forEach((element: { price: number; quantity: number; }) => amount += element.price * element.quantity)
    return amount
  }

  checkQuantityofLineItems(data: any) {
    let quantity = 0
    data.forEach((element: { quantity: number; }) => quantity += element.quantity);
    return quantity
  }

  getActiveVendorsList(){
    return this.http.get(api.URL + '/api/vendor/activeVendors');
  }

}
